import React from 'react';

import "./contactmeSec.scss";

const ContactmeSec = () => {

    const handleMailBtnClick = () =>{
        window.location.href = "mailto:rishirajsalam@gmail.com";
    }

  return (
    <div className='contactme'>
        <div className='sectiontitle'>
            <span>Get In Touch</span>
        </div>
        <div className='sectionDescHolder'>
            <div className='sectiondesc flex_md_50'>
                I am currently looking for new opportunities, 
                my inbox is always open. Whether you have a question or just
                want to say hi, I'll try my best to get back to you!
            </div>
        </div>
        <div className='mailBtnContainer'>
            <div className="mailBtn" onClick={handleMailBtnClick}>
                Say Hello
            </div>
        </div>

    </div>
  )
}

export default ContactmeSec