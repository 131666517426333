import React,{useState} from "react";
import "./experienceSec.scss";

import { BiRightArrow } from "react-icons/bi";

const ExperienceSec = () => {
  const [work, setWork] = useState({
    work:"tantha"
  })
  const toggleExperience = (work) => {
    setWork({work: work});
  };

  return (
    <div className="experience">
      {/* work menu section */}
      <div className="workmenu flex_md_25">
        <div onClick={() => toggleExperience("tantha")}   
        className={work.work === "tantha" ? ("menuholder menuactive"): ("menuholder")}>
          <span>Tantha</span>
        </div>
        <div onClick={() => toggleExperience("lufytech")} 
        className={work.work === "lufytech" ? ("menuholder menuactive"): ("menuholder")}>
          <span>Lufytech</span>
        </div>
        <div onClick={() => toggleExperience("ingelei")} 
        className={work.work === "ingelei" ? ("menuholder menuactive"): ("menuholder")}>
          <span>Ingelei</span>
        </div>
        <div onClick={() => toggleExperience("azureskynet")} 
        className={work.work === "azureskynet" ? ("menuholder menuactive"): ("menuholder")}>
          <span>Azure Skynet</span>
        </div>
      </div>

      {/* work description section */}
      {work.work ==="tantha"?(
        <div className="workDetailContainer">
        <div className="detailHolder">
          <div className="roleDetail">
            <div className="title">
              <span className="role">Full-stack Engineer</span>
              <span className="place">@Tantha</span>
            </div>
            <div className="timeline">
              <span>September 2020 - Present</span>
            </div>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Design, develop, deploy, and maintain OTT(Over The Top) platform for On-demand media
              streaming and movie booking site.
            </span>
          </div>
          
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Work with a variety of different languages, platforms, frameworks,
              payment gateways, and content management systems such as
              JavaScript, EJS, React, NodeJs, Cloudinary, DigitalOcean, and AWS.
            </span>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Communicate with multi-disciplinary teams of engineers, designers,
              producers, and clients on a weekly basis.
            </span>
          </div>
        </div>
      </div>
      ): ""}

      {work.work ==="lufytech"?(
        <div className="workDetailContainer">
        <div className="detailHolder">
          <div className="roleDetail">
            <div className="title">
              <span className="role">Co-founder & Software Engineer</span>
              <span className="place">@Lufytech</span>
            </div>
            <div className="timeline">
              <span>September 2019 - October 2021</span>
            </div>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Design and develop system architecture for internal and client projects. 
            </span>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Work with a variety of different languages, platforms, frameworks,
              payment gateways, and content management systems such as
              JavaScript, EJS, React, NodeJs, Cloudinary, DigitalOcean, and AWS.
            </span>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Communicate with multi-disciplinary teams of engineers, designers,
              and clients on a weekly basis.
            </span>
          </div>
        </div>
      </div>
      ): ""}
      {work.work ==="ingelei"?(
        <div className="workDetailContainer">
        <div className="detailHolder">
          <div className="roleDetail">
            <div className="title">
              <span className="role">Software Engineer</span>
              <span className="place">@Ingelei</span>
            </div>
            <div className="timeline">
              <span>September 2019 - September 2020</span>
            </div>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Design, develop, and maintain internal Employee Management System. 
            </span>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Work with a variety of different languages, platforms, frameworks,
              payment gateways, and content management systems such as
              JavaScript, EJS, React, NodeJs, Cloudinary, DigitalOcean, and AWS.
            </span>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Communicate with multi-disciplinary teams of engineers and designers
              on a daily basis.
            </span>
          </div>
        </div>
      </div>
      ): ""}
      {work.work ==="azureskynet"?(
        <div className="workDetailContainer">
        <div className="detailHolder">
          <div className="roleDetail">
            <div className="title">
              <span className="role">Cyber Security Intern</span>
              <span className="place">@Azure Stynet</span>
            </div>
            <div className="timeline">
              <span>December 2018 - February 2019</span>
            </div>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Learn about Social Engineering, Cryptography, Denial-of-Service, Network Scanning.
            </span>
          </div>
          <div className="taskDetailHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Web application penetration testing.
            </span>
          </div>
          
        </div>
      </div>
      ): ""}
      
    </div>
  );
};

export default ExperienceSec;
