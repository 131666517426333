import React from "react";
import "./workSec.scss";

import project1 from "../../assets/img/project1.png";
import project2 from "../../assets/img/project2.png";
import project3 from "../../assets/img/project3.png";
import { BiRightArrow, BiLeftArrow } from "react-icons/bi";

const WorkSec = () => {
  return (
    <div className="worksection">
      <div className="sectionContainer">
      
        <div className="detailSection flex_md_50">
          <div className="projectType">
            <span>Featured Project</span>
          </div>
          <div className="projectName">
            <span>Tantha Admin Panel</span>
          </div>

          {/* mobile view thumbnail holder */}
          <div className="mobileViewImageContainer">
            <div className="mobileViewImageholder">
              <img src={project2} alt="thumbnail" />
            </div>
          </div>

          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Design, develop, deploy, and maintain OTT platform with 50k+ daily
              active users.
            </span>
          </div>
          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Integrated cloud storage (AWS S3, Cloudinary) for on-demand media
              streaming.
            </span>
          </div>
          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Developed in-house video transcoder with ffmpeg for HLS streaming.
            </span>
          </div>
          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Integrated REST API for mobile and web apps.
            </span>
          </div>
          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Integrated multiple payment gateways.
            </span>
          </div>
        </div>
        <div className="imageContainer flex_md_50">
        <div className="imagewrapper">
            <div className="imageholder">
              <img src={project2} alt="thumbnail" />
            </div>
          </div>
        </div>
        
      </div>
      <div className="sectionContainer">
      <div className="imageContainer flex_md_50">
          <div className="imagewrapper">
            <div className="imageholder">
              <img src={project1} alt="thumbnail" />
            </div>
          </div>
        </div>
        <div className="detailSection flex_md_50">
          <div className="projectTypeRA">
            <span>Featured Project</span>
          </div>
          <div className="projectNameRA">
            <span>Tanthapolis</span>
          </div>

          {/* mobile view thumbnail holder */}
          <div className="mobileViewImageContainer">
            <div className="mobileViewImageholder">
              <img src={project1} alt="thumbnail" />
            </div>
          </div>

          <div className="projDescHolder">
          <span className="iconHolderMobile">
              <BiRightArrow />
            </span>
            <span className="taskDetailRA">
              Design, develop, deploy, and maintain online movie booking
              platform.
            </span>
            <span className="iconHolderRA">
              <BiLeftArrow />
            </span>
          </div>
          <div className="projDescHolder">
          <span className="iconHolderMobile">
              <BiRightArrow />
            </span>
            <span className="taskDetailRA">
              Integrated REST API for mobile and web apps.
            </span>
            <span className="iconHolderRA">
              <BiLeftArrow />
            </span>
          </div>
          <div className="projDescHolder">
          <span className="iconHolderMobile">
              <BiRightArrow />
            </span>
            <span className="taskDetailRA">
              Integrated multiple payment gateways.
            </span>
            <span className="iconHolderRA">
              <BiLeftArrow />
            </span>
          </div>
        </div>
        
      </div>
      
      <div className="sectionContainer">
        <div className="detailSection flex_md_50">
          <div className="projectType">
            <span>Ongoing Project</span>
          </div>
          <div className="projectName">
            <span>MiRA(Mixed Reality as a shared experience in the museum space)</span>
          </div>

          {/* mobile view thumbnail holder */}
          <div className="mobileViewImageContainer">
            <div className="mobileViewImageholder">
              <img src={project3} alt="thumbnail" />
            </div>
          </div>

          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Design and develop web app and desktop app (Electron Js app).
            </span>
          </div>
          <div className="projDescHolder">
            <span className="iconHolder">
              <BiRightArrow />
            </span>
            <span className="taskDetail">
              Implepment data warehousing to create configuration for the mixed reality scenarios.
            </span>
          </div>
        </div>
        <div className="imageContainer flex_md_50">
        <div className="imagewrapper">
            <div className="imageholder">
              <img src={project3} alt="thumbnail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkSec;
