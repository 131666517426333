import {
  BrowserRouter,
  Routes,
  Route,
  Outlet
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";



import "./style.scss";
import "./app.scss";

import Home from "./pages/home/Home";
import Navbar from "../src/components/navbar/Navbar";

import Leftbar from "../src/components/leftbar/Leftbar";

function App() {
  const queryClient = new QueryClient();
  

  const Layout = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <div className={"theme-dark"}>
          <Navbar />
          <div style={{ display: "flex" }}>
              <div>
                <Leftbar />
              </div>
              <div>
                <Outlet className="layout" />
              </div>
            </div>
        </div>
      </QueryClientProvider>
    );
  };

  return (
    <div>
      <BrowserRouter>
        <div className="theme-dark">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
