import React from 'react'
import { Link } from "react-router-dom";
import './leftbar.scss'

import { BsInstagram } from "react-icons/bs";
import { FiTwitter, FiGithub, FiLinkedin } from "react-icons/fi";

const Leftbar = () => {
  const handleUrlRedirect = (url) =>{
    window.location.href = url;
  }
  return (
    <div className='customLeftbar'>
      <div className="itemContainer">
        <div>
        <Link onClick={()=> handleUrlRedirect('https://github.com/rishiraj09')} state={{textDecoration: "none"}}>
          <FiGithub/>
        </Link>
        </div>
        <div>
        <Link onClick={()=> handleUrlRedirect('https://instagram.com/rishirajsalam')} state={{textDecoration: "none"}}>
          <BsInstagram/>
        </Link>
        </div>
        <div>
        <Link onClick={()=> handleUrlRedirect('https://twitter.com/RishirajSalam')} state={{textDecoration: "none"}}>
          <FiTwitter/>
        </Link>
        </div>
        <div>
        <Link onClick={()=> handleUrlRedirect('https://www.linkedin.com/in/rishiraj-salam-78ba32151/')} state={{textDecoration: "none"}}>
          <FiLinkedin/>
        </Link>
        </div>
        <div>
          <div className='dividerLine'>
            <div className="bleft"></div>
            <div className="bright"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Leftbar