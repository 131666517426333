import React from "react";
import { Link } from "react-router-dom";
import "./home.scss";

import myPhoto from "../../assets/img/rishiraj.jpeg";
import { BiRightArrow } from "react-icons/bi";
import ExperienceSec from "../../components/experienceSec/ExperienceSec";
import WorkSec from "../../components/workSec/WorkSec";
import ContactmeSec from "../../components/contactmeSec/ContactmeSec";

import { BsInstagram } from "react-icons/bs";
import { FiTwitter, FiGithub, FiLinkedin } from "react-icons/fi";

const Home = () => {
  const handleUrlRedirect = (url) =>{
    window.location.href = url;
  }

  return (
    <div className="home">
      <div className="homecontainer flex_md_80">
        <div className="intro1" id="intro-sec">
          <span>Hi, my name is</span>
        </div>
        <div className="introName">
          <span>Rishiraj Salam.</span>
        </div>
        <div className="intro2">
          <span>I am a Software Developer.</span>
        </div>
        <div className="intro3">
          <span>
            I am a software developer specializing in building (and occasionally
            designing)
          </span>
          <br />
          <span>exceptional digital experiences.</span>
        </div>

        {/* 01. About me */}

        <div className="sectionTitleContainer" id="aboutme-sec">
          <div className="sectionTitleHolder flex_md_50">
            <span className="titleCount">01.</span>
            <span className="titleName">About Me</span>
            <hr />
          </div>
        </div>
        <div className="aboutMeContainer">
          <div className="flex_md_50">
            <p>
              Hello! My name is Rishiraj and I enjoy creating things that live
              on the internet. My interest in web development started back in
              2016 when I decided to build a website for my friend -- turns out
              building a static site taught me a lot about HTML, CSS &
              JavaScript!
            </p>
            <p>
              Fast-forward to today, and I've had the privilege of working at{" "}
              <span style={{ color: "#5098B3" }}>
                {" "}
                a paper industry, a start-up,{" "}
              </span>{" "}
              and{" "}
              <span style={{ color: "#5098B3" }}>
                media & entertainment industry
              </span>
              . My main focus these days is building accessible, inclusive
              products and digital experience working as a freelancer for a
              variety of clients.
            </p>
            <p>
              I also recently launched a movie booking web app using Node &
              React.
            </p>

            <p>Here are a few technologies I've been working with recently:</p>
            <div className="techStackSection">
              <div className="flex_md_50">
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">JavaScript (ES6+)</span>
                </div>
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">React</span>
                </div>
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">Node.js</span>
                </div>
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">MongoDb</span>
                </div>
              </div>
              <div className="flex_md_50">
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">Python</span>
                </div>
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">Flask</span>
                </div>
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">Django</span>
                </div>
                <div className="stackHolder">
                  <span className="rightIcon">
                    <BiRightArrow />
                  </span>
                  <span className="stackName">PostgreSQL</span>
                </div>
              </div>
            </div>
          </div>

          {/* photo section */}
          <div className="flex_md_50 photoContainer">
            <div className="photoHolder">
              <div
                className="imgContainer"
                style={{ backgroundImage: `url(${myPhoto})` }}
              >
                <div className="effectlayer"></div>
              </div>
              <div className="photoFrame"></div>
            </div>
          </div>
        </div>

        {/* experience section */}
        <div
          className="sectionTitleContainer"
          id="experience-sec"
          style={{ marginBottom: "50px" }}
        >
          <div className="sectionTitleHolder flex_md_70">
            <span className="titleCount">02.</span>
            <span className="titleName">Where I've Worked</span>
            <hr />
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <ExperienceSec />

        {/* work section */}

        <div
          className="sectionTitleContainer"
          id="work-sec"
          style={{ marginBottom: "50px" }}
        >
          <div className="sectionTitleHolder flex_md_70">
            <span className="titleCount">03.</span>
            <span className="titleName">Some Things I've Built</span>
            <hr />
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        <WorkSec />

        {/* contact me section */}
        <div
          className="sectionTitleContainer"
          id="contact-sec"
          style={{ marginBottom: "50px" }}
        >
          <div className="sectionTitleHolder" style={{ width: "100%" }}>
            <span className="titleCount">04.</span>
            <span className="titleName">Contact Me</span>
          </div>
        </div>
        <ContactmeSec />

        <div className="socialIcons">
          <div>
            <Link
              onClick={() => handleUrlRedirect("https://github.com/rishiraj09")}
              state={{ textDecoration: "none" }}
            >
              <FiGithub />
            </Link>
          </div>
          <div>
            <Link
              onClick={() =>
                handleUrlRedirect("https://instagram.com/rishirajsalam")
              }
              state={{ textDecoration: "none" }}
            >
              <BsInstagram />
            </Link>
          </div>
          <div>
            <Link
              onClick={() =>
                handleUrlRedirect("https://twitter.com/RishirajSalam")
              }
              state={{ textDecoration: "none" }}
            >
              <FiTwitter />
            </Link>
          </div>
          <div>
            <Link
              onClick={() =>
                handleUrlRedirect(
                  "https://www.linkedin.com/in/rishiraj-salam-78ba32151/"
                )
              }
              state={{ textDecoration: "none" }}
            >
              <FiLinkedin />
            </Link>
          </div>
        </div>

        <div className="footer">Designed & Built by Rishiraj Salam</div>
      </div>
    </div>
  );
};

export default Home;
