import React from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo.png";
import { CgMenuRight } from "react-icons/cg";
// import { VscClose } from "react-icons/vsc";


const Navbar = () => {
  

  const handleClickScroll = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      let navbar = document.querySelector(".nav-bar");
      navbar.classList.toggle("active");
    }
  };

  const handleNavToggle = () =>{
    let navbar = document.querySelector(".nav-bar");
      navbar.classList.toggle("active");
  }

  return (
    <div className="customnavbar">
      <div className="logo">
        <Link onClick={() => handleClickScroll('intro-sec')} style={{ textDecoration: "none" }}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="hamburger">
        {/* {navbar ? <VscClose onClick={toggleNavbar} /> : <CgMenuRight onClick={toggleNavbar} />} */}
        <CgMenuRight onClick={handleNavToggle} />
      </div>
      
      <div className="nav-bar">
        <ul>
          <li>
            <Link onClick={() => handleClickScroll('aboutme-sec')}>
              <div className="navmenu">
                <span className="menuindex">01.</span>
                <span className="menuname">About</span>
              </div>
            </Link>
          </li>
          <li>
            <Link onClick={() => handleClickScroll('experience-sec')}>
              <div className="navmenu">
                <span className="menuindex">02.</span>
                <span className="menuname">Experience</span>
              </div>
            </Link>
          </li>
          <li>
            <Link onClick={() => handleClickScroll('work-sec')}>
              <div className="navmenu">
                <span className="menuindex">03.</span>
                <span className="menuname">Work</span>
              </div>
            </Link>
          </li>
          <li>
            <Link onClick={() => handleClickScroll('contact-sec')}>
              <div className="navmenu">
                <span className="menuindex">04.</span>
                <span className="menuname">Contact</span>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
